<template>
	<page-container class="index" :page="page">
		<heading-image>
			<hero-image>
				<div class="slider-overlay" />
				<div style="padding: 56.25% 0 0; position: relative">
					<iframe
						src="https://player.vimeo.com/video/948071740?h=f98f433ce5&autoplay=1&loop=1&title=0&controls=0&byline=0&portrait=0&muted=1"
						style="position: absolute; top: 0; left: 0; width: 100%; height: 100%"
						frameborder="0"
						allow="autoplay; fullscreen; picture-in-picture"
						allowfullscreen
					/>
				</div>
			</hero-image>
		</heading-image>

		<main-content>
			<div class="row">
				<div class="columns column6 intro-image">
					<picture>
						<source :srcset="defaults[locale].homepage.introImageWebp" type="image/webp" />
						<source :srcset="defaults[locale].homepage.introImage" />
						<img
							:src="defaults[locale].homepage.introImage"
							:alt="defaults[locale].homepage.introImageAlt"
							loading="lazy"
						/>
					</picture>
				</div>
				<div class="columns column6">
					<h2 v-if="page.subtitle" class="subtitle">
						{{ page.subtitle }}
					</h2>
					<h1>{{ page.title }}</h1>
					<div v-parse-links v-html="page.content" />
				</div>
			</div>
			<picture class="intro-bg">
				<source srcset="~/assets/images/intro-content-bg.webp" type="image/webp" />
				<source srcset="~/assets/images/intro-content-bg.png" />
				<img src="~/assets/images/intro-content-bg.png" alt="Quinta Das Maravilhas" loading="lazy" />
			</picture>
		</main-content>

		<mosaic :items="mosaicData" />

		<contentblocks :items="page.contentblocks">
			<template #blocks-header>
				<div class="row blocks-header white align-center">
					<div class="columns column12">
						<h2>
							{{ defaults[locale].homepage.sectionContentblocksTitle }}
						</h2>
						<div v-parse-links v-html="defaults[locale].homepage.sectionContentblocksContent" />
					</div>
				</div>
			</template>
		</contentblocks>

		<promoblocks :items="promoblocksData">
			<template #blocks-header>
				<div class="row blocks-header align-center">
					<div class="columns column12">
						<h2>{{ defaults[locale].homepage.sectionPromotionTitle }}</h2>
						<div v-parse-links v-html="defaults[locale].homepage.sectionPromotionContent" />
					</div>
				</div>
			</template>
		</promoblocks>

		<frequently-asked-questions
			v-if="page.frequentlyAskedQuestion"
			:items="page.frequentlyAskedQuestion.faqitems"
			:header="page.frequentlyAskedQuestion.header"
		/>

		<section class="snapwidget-section">
			<div class="row">
				<div class="columns column12 align-center">
					<h2>{{ defaults[locale].homepage.snapWidgetTitle }}</h2>
				</div>
				<div class="columns column12 snap-section">
					<client-only>
						<component :is="'script'" src="https://snapwidget.com/js/snapwidget.js" async />
						<iframe
							src="https://snapwidget.com/embed/1077128"
							class="snapwidget-widget"
							allowtransparency="true"
							frameborder="0"
							scrolling="no"
							style="border: none; overflow: hidden; width: 100%"
							title="Posts from Instagram"
						/>
					</client-only>
				</div>
			</div>
		</section>
	</page-container>
</template>

<script setup>
const { page, fetchPage } = usePage();
const { defaults, fetchDefaults } = useDefaults();
const { locale } = useI18n();

await fetchPage();

if (!defaults.value) {
	await fetchDefaults();
}

const { data: mosaicData } = await useWebsiteFetch('mosaic', {
	query: { language: locale.value },
	key: `${locale.value}/mosaic`,
});

const { data: promoblocksData } = await useWebsiteFetch('promoblocks', {
	query: { language: locale.value },
	key: `${locale.value}/promoblocks`,
});
</script>

<style lang="scss" scoped>
.main-content.intro {
	position: relative;

	.row {
		position: relative;
		z-index: 2;
	}
}

.intro-bg {
	object-fit: cover;
	position: absolute;
	inset: 0;
	z-index: 1;

	img {
		width: 100%;
		height: 100% !important;
		object-fit: cover;
	}
}

.slider-overlay {
	position: absolute;
	inset: 0;
	z-index: 2;
	background: rgb(0 0 0 / 25%);
}

.intro {
	.column8 {
		padding: 20px 60px;
		position: relative;
		z-index: 2;
	}

	.column6 {
		position: relative;
		z-index: 2;
	}
}

.hero-content {
	position: absolute;
	max-width: 860px;
	z-index: 10;
	left: 0;
	right: 0;
	width: auto;
	bottom: 50%;
	margin: 0 auto -140px;
	text-align: center;
	color: #fff;
	letter-spacing: 2px;

	:deep(h2) {
		color: #fff;
		font-size: 5.2em;
		line-height: 1.3em;
	}

	:deep(p) {
		font-size: 1.5em;
	}
}

.blocks-header {
	flex-flow: row wrap;

	.column12 {
		flex-flow: row wrap;

		h2 {
			width: 100%;
		}
	}
}

.snap-section {
	margin: 25px auto 0;
}

@media (max-width: 1080px) {
	.intro-image {
		display: none;
	}
}

@media (max-width: 880px) {
	.hero-content {
		width: 96%;

		:deep(h2) {
			font-size: 3.4em;
		}

		:deep(p) {
			font-size: 1.6em;
			padding: 0 10px;
		}
	}
}

@media (max-width: 720px) {
	.hero-content {
		margin: 0 auto -110px;

		:deep(h2) {
			font-size: 2.6em;
		}

		:deep(p) {
			font-size: 1.4em;
			padding: 0 10px;
		}
	}
}
</style>
